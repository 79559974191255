export function lgLicenseKey() {
  return 'D8D737F0-45BE-4944-BFAB-0BD5C471AD70';
}

export default function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function getBackendUrl() {
  return isDev() ? 'http://localhost:3100' : '';
}

export function formatDate(dateRaw: string) {
  const date = new Date(Date.parse(dateRaw));
  return date.toLocaleDateString('cs-CZ').replaceAll(' ', '');
}

export function preparePhotoUrl(relativePath: string) {
  return (
    'https://procontact.fra1.digitaloceanspaces.com/photos/' + relativePath
  );
}

export function prepareAttachImageUrl(
  relativePath: string,
  childNumber: number,
  newPath: boolean,
) {
  if (newPath) {
    const childNumberString = String(childNumber).padStart(4, '0');
    return `https://procontact.fra1.digitaloceanspaces.com/child/${childNumberString}/${relativePath}`;
  } else {
    return preparePhotoUrl(relativePath);
  }
}

export function formatChildSex(sex: string) {
  if (sex.toLowerCase() === 'm') {
    return 'muž';
  }
  if (sex.toLowerCase() === 'f') {
    return 'žena';
  }
  return '';
}

export function formatChildState(state: string, preReserved: boolean) {
  if (preReserved) {
    return 'rezervované';
  }
  if (state === 'for_adoption') {
    return 'k adoptování';
  } else if (state === 'reserved') {
    return 'rezervované';
  } else if (state === 'adopted') {
    return 'adoptované';
  } else {
    return 'neznámý';
  }
}
