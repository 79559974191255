import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ChildrenList from './components/ChildrenList';
import LeftMenu from './components/LeftMenu';
import Header from './components/Header';
import { ChildDetail } from './components/ChildDetail';
import NewsList from './pages/NewsList';
import { NewsDetail } from './pages/NewsDetail';
import { UserProvider } from './contexts/UserContext';
import StopImpersonationBlock from './components/Impersonation/StopImpersonationBlock';
import { PageDetail } from './pages/PageDetail';
import NavigationBar from './components/NavigationBar';
import Adoptions from './pages/profile/Adoptions';
import Profile from './pages/profile/Profile';
import Backoffice from './pages/backoffice/Backoffice';
import ChildrenSearch from './components/ChildrenSearch';
import { PhotoGalleryList } from './pages/PhotoGallery/List';
import { PhotoGalleryDetail } from './pages/PhotoGallery/Detail';
import { SupportedBy } from './pages/SupportedBy';
import ChangePasswordRequest from './pages/profile/ChangePasswordRequest';
import BackofficeEmailQueueList from './pages/backoffice/EmailQueue';
import BackofficeEmailTemplatesList from './pages/backoffice/EmailTemplates';
import { UserRegistration } from './pages/profile/Registration';
import { EmailVerify } from './pages/profile/EmailVerify';
import ChangePassword from './pages/profile/ChangePassword';
import ProfileLogin from './pages/profile/Login';
import UserTransactions from './pages/profile/UserTransactions';
import BackofficeTransactionsList from './pages/backoffice/transactions/list';
import YouTubeUpload from './pages/backoffice/YouTubeUpload';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { BankCertificates } from './pages/backoffice/bankCertificates/BankCertificates';
import CookieConsent from './components/CookieConsent';
import ChildLegacy from './components/ChildLegacy';
import { SupportUs } from './pages/SupportUs';
import AdoptionDetail from './pages/backoffice/adoption/detail';
import { BackofficeReminders } from './pages/backoffice/payment-requests/reminders';
import { BackofficeTransactionsSummary } from './pages/backoffice/transactions/summary';
import { BackofficeExpiredPaymentRequests } from './pages/backoffice/payment-requests/expired';
import BackofficeUsersList from './pages/backoffice/users/UsersList';
import UserDetail from './pages/backoffice/users/UserDetail';
import BackofficeUserEmailSearch from './pages/backoffice/users/UserEmailSearch';
import BackofficeChildrenList from './pages/backoffice/children/ChildrenList';
import { PostList } from './pages/backoffice/adoption/postList';
import BackofficeNewsList from './pages/backoffice/news/list';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <CookieConsent />
        {/*
        <TawkMessengerReact
          propertyId="66bc38320cca4f8a7a75ab13"
          widgetId="1i57jn177"
        />*/}
        <div className="container-md ppage">
          <div className="row">
            <div className="col pheader">
              <Header />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <NavigationBar />
            </div>
          </div>
          <StopImpersonationBlock />
          <div className="row">
            <div className="col pleft col-12 col-md-2">
              <div className="pcontainer">
                <LeftMenu />
              </div>
            </div>
            <div className="col pcontent col-12 col-md-10">
              <div className="ppcontent">
                <Routes>
                  <Route path="/" element={<NewsList />}></Route>
                  <Route path="/news/page/:page" element={<NewsList />}></Route>
                  <Route path="/page/:slug" element={<PageDetail />}></Route>
                  <Route path="/news/:slug" element={<NewsDetail />}></Route>
                  <Route
                    path="/photogallery/list"
                    element={<PhotoGalleryList />}
                  ></Route>
                  <Route
                    path="/photogallery/:slug"
                    element={<PhotoGalleryDetail />}
                  ></Route>
                  <Route
                    path="/children/:listType"
                    element={<ChildrenList />}
                  ></Route>
                  <Route
                    path="/children/:listType/page/:page"
                    element={<ChildrenList />}
                  ></Route>
                  <Route
                    path="/children/search"
                    element={<ChildrenSearch />}
                  ></Route>
                  <Route
                    path="/children/search/page/:page"
                    element={<ChildrenSearch />}
                  ></Route>
                  <Route
                    path="/child/:childNumber"
                    element={<ChildDetail />}
                  ></Route>
                  <Route
                    path="/cs/children/view/:childNumber"
                    element={<ChildLegacy />}
                  ></Route>
                  <Route path="/supported-by" element={<SupportedBy />}></Route>
                  <Route path="/support-us" element={<SupportUs />}></Route>
                  <Route
                    path="/backoffice"
                    element={<Backoffice></Backoffice>}
                  ></Route>
                  <Route
                    path="/backoffice/news"
                    element={<BackofficeNewsList />}
                  ></Route>
                  <Route
                    path="/backoffice/users"
                    element={<BackofficeUsersList />}
                  ></Route>
                  <Route
                    path="/backoffice/user/:email"
                    element={<UserDetail />}
                  ></Route>
                  <Route
                    path="/backoffice/adoption/:adoptionId"
                    element={<AdoptionDetail></AdoptionDetail>}
                  ></Route>
                  <Route
                    path="/backoffice/user-email-search"
                    element={<BackofficeUserEmailSearch />}
                  ></Route>
                  <Route
                    path="/backoffice/users/page/:page"
                    element={<BackofficeUsersList />}
                  ></Route>
                  <Route
                    path="/backoffice/email-queue"
                    element={<BackofficeEmailQueueList />}
                  ></Route>
                  <Route
                    path="/backoffice/children"
                    element={<BackofficeChildrenList />}
                  ></Route>
                  <Route
                    path="/backoffice/children/page/:page"
                    element={<BackofficeChildrenList />}
                  ></Route>
                  <Route
                    path="/backoffice/adoption/post-list"
                    element={<PostList />}
                  ></Route>
                  <Route
                    path="/backoffice/bank-certificates"
                    element={<BankCertificates />}
                  ></Route>
                  <Route
                    path="/backoffice/email-queue/page/:page"
                    element={<BackofficeEmailQueueList />}
                  ></Route>
                  <Route
                    path="/backoffice/email-templates"
                    element={<BackofficeEmailTemplatesList />}
                  ></Route>
                  <Route
                    path="/backoffice/payment-requests/reminders"
                    element={<BackofficeReminders />}
                  ></Route>
                  <Route
                    path="/backoffice/payment-requests/expired"
                    element={<BackofficeExpiredPaymentRequests />}
                  ></Route>
                  <Route
                    path="/backoffice/transactions"
                    element={<BackofficeTransactionsList />}
                  ></Route>
                  <Route
                    path="/backoffice/transactions/page/:page"
                    element={<BackofficeTransactionsList />}
                  ></Route>
                  <Route
                    path="/backoffice/transactions/summary"
                    element={<BackofficeTransactionsSummary />}
                  ></Route>
                  <Route
                    path="/backoffice/youtube-upload"
                    element={<YouTubeUpload />}
                  ></Route>
                  <Route
                    path="/profile/reset-password"
                    element={<ChangePasswordRequest></ChangePasswordRequest>}
                  ></Route>
                  <Route
                    path="/profile/registration"
                    element={<UserRegistration></UserRegistration>}
                  ></Route>
                  <Route
                    path="/profile/email-verification/:token"
                    element={<EmailVerify></EmailVerify>}
                  ></Route>
                  <Route
                    path="/profile/change-password"
                    element={<ChangePassword></ChangePassword>}
                  ></Route>
                  <Route
                    path="/profile/change-password/:token"
                    element={<ChangePassword></ChangePassword>}
                  ></Route>
                  <Route path="/profile" element={<Profile />}></Route>
                  <Route
                    path="/profile/adoptions"
                    element={<Adoptions />}
                  ></Route>
                  <Route
                    path="/profile/login"
                    element={<ProfileLogin />}
                  ></Route>
                  <Route
                    path="/profile/gift-certificates"
                    element={<UserTransactions></UserTransactions>}
                  ></Route>
                  <Route path="*" element={<h4>Stránka nenalezena</h4>}></Route>
                </Routes>
              </div>
            </div>
          </div>
          <div className="row footer">
            <div className="col col-12">
              Pro-contact.cz (www.pro-contact.cz) Copyright © 2007 |{' '}
              <a href="https://www.facebook.com/procontact.cz">Facebook</a>
            </div>
          </div>
        </div>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
