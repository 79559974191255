/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query PaymentIntervals {\n    paymentIntervals {\n      id\n      treaty\n    }\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n": types.PaymentIntervalsDocument,
    "\n  mutation ReserveChild($childNumber: Int!, $paymentIntervalId: String!) {\n    reserveChild(\n      childNumber: $childNumber\n      paymentIntervalId: $paymentIntervalId\n    ) {\n      success\n    }\n  }\n": types.ReserveChildDocument,
    "\n  query child($fullNumber: String!) {\n    child(fullNumber: $fullNumber) {\n      id\n      name\n      number\n      fullNumber\n      mainPhoto\n      description\n      birthDate\n      sex\n      top\n      visible\n      state\n      preReserved\n      motherName\n      fatherName\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      school {\n        name\n      }\n      coordinator {\n        name\n      }\n      teaser\n      attachmentGroups {\n        id\n        title\n        attachments {\n          id\n          type\n          filename\n          rotationAngle\n          newPath\n        }\n      }\n      storage {\n        files {\n          name\n          path\n          link\n        }\n        directories {\n          name\n          files {\n            name\n            path\n            link\n          }\n        }\n      }\n    }\n    me {\n      role\n    }\n  }\n": types.ChildDocument,
    "\n  mutation ChangeTopChild($childId: String!, $top: Boolean!) {\n    changeTopChild(childId: $childId, top: $top) {\n      success\n    }\n  }\n": types.ChangeTopChildDocument,
    "\n  mutation ChangeVisibleChild($childId: String!, $visible: Boolean!) {\n    changeVisibleChild(childId: $childId, visible: $visible) {\n      success\n    }\n  }\n": types.ChangeVisibleChildDocument,
    "\n  query childList($state: String!) {\n    childList(state: $state) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          top\n          fullNumber\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n": types.ChildListDocument,
    "\n  query childSearch($text: String!) {\n    childSearch(text: $text) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          fullNumber\n          top\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n": types.ChildSearchDocument,
    "\n  query MenuTreeBar {\n    menuTree(slug: \"hlavni-menu\") {\n      title\n      id\n      directUrl\n      page {\n        slug\n      }\n      children {\n        title\n        id\n        directUrl\n        page {\n          slug\n        }\n      }\n    }\n  }\n": types.MenuTreeBarDocument,
    "\n  query RandomAdoptionOffer($count: Int) {\n    randomAdoptionOffer(count: $count) {\n      name\n      fullNumber\n      mainPhoto\n    }\n  }\n": types.RandomAdoptionOfferDocument,
    "\n  query AdoptionSummary {\n    adoptionSummary {\n      for_adoption\n      adopted\n      reserved\n    }\n  }\n": types.AdoptionSummaryDocument,
    "\n  query MenuTreeNav {\n    menuTree(slug: \"hlavni-menu\") {\n      title\n      id\n      directUrl\n      page {\n        slug\n      }\n      children {\n        title\n        id\n        directUrl\n        page {\n          slug\n        }\n      }\n    }\n  }\n": types.MenuTreeNavDocument,
    "\n  query QueryUserInfo {\n    me {\n      username\n      email\n      phone\n      role\n    }\n  }\n": types.QueryUserInfoDocument,
    "\n  mutation Login($username: String!, $password: String!) {\n    login(data: { username: $username, password: $password }) {\n      user {\n        username\n        email\n        phone\n        role\n      }\n      accessToken\n    }\n  }\n": types.LoginDocument,
    "\n  mutation Impersonation($userId: String!) {\n    getTokenForImpersonation(userId: $userId) {\n      accessToken\n    }\n  }\n": types.ImpersonationDocument,
    "\n  query NewsSlug($slug: String!) {\n    news(slug: $slug) {\n      id\n      title\n      createdAt\n      slug\n      teaser\n      content\n      mainPhoto\n    }\n  }\n": types.NewsSlugDocument,
    "\n  query NewsList {\n    newsList {\n      edges {\n        node {\n          id\n          title\n          slug\n          content\n          createdAt\n          teaser\n          mainPhoto\n        }\n      }\n    }\n  }\n": types.NewsListDocument,
    "\n  query page($slug: String!) {\n    page(slug: $slug) {\n      title\n      content\n    }\n  }\n": types.PageDocument,
    "\n  query photoGallery($slug: String!) {\n    photoGallery(slug: $slug) {\n      id\n      slug\n      name\n      description\n      createdAt\n      photos {\n        id\n        description\n        filename\n        rotationAngle\n      }\n    }\n  }\n": types.PhotoGalleryDocument,
    "\n  query GalleriesList {\n    galleriesList {\n      edges {\n        node {\n          id\n          name\n          slug\n          description\n          createdAt\n          photos {\n            id\n            description\n            filename\n            rotationAngle\n          }\n        }\n      }\n    }\n  }\n": types.GalleriesListDocument,
    "\n  query Supporters {\n    supporters {\n      id\n      name\n      description\n      logo\n      link\n    }\n  }\n": types.SupportersDocument,
    "\n  query QueuedEmailList($status: String, $email: String) {\n    queuedEmailList(status: $status, email: $email) {\n      edges {\n        node {\n          id\n          createdAt\n          sentAt\n          type\n          subject\n          htmlBody\n          email\n          status\n        }\n      }\n    }\n  }\n": types.QueuedEmailListDocument,
    "\n  mutation ChangeEmailTemplate(\n    $id: String!\n    $subject: String!\n    $body: String!\n  ) {\n    changeEmailTemplate(data: { id: $id, subject: $subject, body: $body }) {\n      id\n    }\n  }\n": types.ChangeEmailTemplateDocument,
    "\n  query EmailTemplates {\n    emailTemplates {\n      id\n      type\n      subject\n      body\n    }\n  }\n": types.EmailTemplatesDocument,
    "\n  mutation AttachVideoLink(\n    $childNumber: Int!\n    $groupName: String!\n    $link: String!\n  ) {\n    attachVideoLink(\n      input: {\n        childNumber: $childNumber\n        attachmentGroup: $groupName\n        link: $link\n      }\n    ) {\n      success\n      message\n    }\n  }\n": types.AttachVideoLinkDocument,
    "\n  mutation CancelAdoption($adoptionId: String!, $keep: Boolean!) {\n    backofficeCancelAdoption(adoptionId: $adoptionId, keep: $keep) {\n      success\n    }\n  }\n": types.CancelAdoptionDocument,
    "\n  mutation AdminAdoptionPaymentIntervalChange($adoptionId: String!, $paymentIntervalId: String!) {\n    adminAdoptionPaymentIntervalChange(adoptionId: $adoptionId, paymentIntervalId: $paymentIntervalId) {\n      success\n    }\n  }\n": types.AdminAdoptionPaymentIntervalChangeDocument,
    "\n  query getAdoptionDetails($adoptionId: String!) {\n    adminAdoptionDetail(adoptionId: $adoptionId) {\n      id\n      user {\n        email\n      }\n      child {\n        name\n        fullNumber\n      }\n      paymentRequests {\n        id\n        paymentDate\n        paid\n        skip\n        intervalDate {\n          paymentInterval {\n            id\n            treaty\n            price\n            intervalReminders {\n              id\n              daysBefore\n            }\n          }\n        }\n        reminders {\n          remindedAt\n          intervalReminder {\n            id\n            daysBefore\n          }\n        }\n      }\n      paymentInterval {\n        id\n        treaty\n      }\n      lastPayment\n      lastPaymentTx\n      nextPayment\n    }\n    \n    paymentIntervals {\n      id\n      treaty\n    }\n  }\n": types.GetAdoptionDetailsDocument,
    "\n  mutation AdminSkipPaymentRequest($paymentRequestId: String! $skip: Boolean!) {\n    adminSkipPaymentRequest(paymentRequestId: $paymentRequestId, skip: $skip) {\n      success\n    }\n  }\n": types.AdminSkipPaymentRequestDocument,
    "\n  query GetPostList {\n    postList {\n      userId\n      childNumbers\n      address {\n        id\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n  }\n": types.GetPostListDocument,
    "\n  query GetBankCertificates {\n    bankCertificates {\n      id\n      filename\n      active\n      createdAt\n      updatedAt\n    }\n  }\n": types.GetBankCertificatesDocument,
    "\n  query BackofficeChildList(\n    $text: String\n    $state: String\n    $visible: Boolean\n    $coordinatorId: String\n  ) {\n    backofficeChildList(\n      input: { text: $text, visible: $visible, coordinatorId: $coordinatorId, state: $state }\n    ) {\n      id\n      name\n      number\n      fullNumber\n      state\n      visible\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      coordinator {\n        id\n        name\n        sign\n      }\n    }\n    listCoordinators {\n      id\n      name\n      sign\n    }\n  }\n": types.BackofficeChildListDocument,
    "\n  mutation CreateOrUpdateNews(\n    $id: String\n    $title: String!\n    $teaser: String!\n    $mainPhoto: String\n  ) {\n    createOrUpdateNews(\n      input: { id: $id, title: $title, teaser: $teaser, mainPhoto: $mainPhoto }\n    ) {\n      id\n    }\n  }\n": types.CreateOrUpdateNewsDocument,
    "\n  query BackofficeNewsList {\n    newsList {\n      edges {\n        node {      \n          id\n          title\n          slug\n          teaser\n          mainPhoto\n          content\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.BackofficeNewsListDocument,
    "\n  query BackofficeExpiredPaymentRequests {\n    backofficeExpiredPaymentRequests {\n      id\n      childNumber\n      childName\n      reserved\n      adoptionId\n      email\n      interval\n      paymentDate\n      daysLate\n    }\n  }\n": types.BackofficeExpiredPaymentRequestsDocument,
    "\n  query AdminUpcomingReminders {\n    adminUpcomingReminders {\n      id\n      remindedAt\n      reminderDate\n      email {\n        id\n        createdAt\n        email\n        type\n        subject\n        status\n        sentAt\n        htmlBody\n      }\n      intervalReminder {\n        daysBefore\n        paymentInterval {\n          treaty\n        }\n      }\n      paymentRequest {\n        id\n        paymentDate\n        paid\n        adoption {\n          id\n          child {\n            fullNumber\n          }\n          user {\n            email\n          }\n        }\n      }\n    }\n  }\n": types.AdminUpcomingRemindersDocument,
    "\n  query GetTxDetail($id: String) {\n    adminTransactionDetail(id: $id) {\n      id\n      timestamp\n      type\n      source\n      user {\n        id\n      }\n      child {\n        id\n      }\n      status\n      value\n      currency\n      description\n      bankReference\n      bankSourceAccountPrefix\n      bankSourceAccountNumber\n      bankSourceAccountCode\n      bankSourceAccountName\n      bankMessage\n      bankVariableSymbol\n      bankConstantSymbol\n      paymentRequests {\n        id\n      }\n    }\n    usersList {\n      edges {\n        node {\n          id\n          mailingAddress {\n            name\n          }\n          email\n        }\n      }\n    }\n    adminChildList {\n      id\n      fullNumber\n      number\n      name\n      adoption {\n        user {\n          id\n        }\n      }\n    }\n  }\n": types.GetTxDetailDocument,
    "\n  query AdminPaymentRequest($userId: String! $childId: String!) {\n    adminPaymentRequest(userId: $userId, childId: $childId) {\n      id\n      paymentDate\n      paid\n      intervalDate {\n        paymentInterval {\n          price\n        }\n      }\n    }\n  }\n": types.AdminPaymentRequestDocument,
    "\n  mutation CreateOrUpdateUserTx(\n    $id: String\n    $timestamp: DateTime!\n    $type: String!\n    $userId: UserId\n    $childId: ChildId\n    $value: Int!\n    $currency: String!\n    $description: String\n    $status: String!\n    $paymentRequests: [PaymentRequestId!]!\n    $sendInfoEmail: Boolean\n  ) {\n    createOrUpdateTransaction(\n      data: {\n        id: $id\n        timestamp: $timestamp\n        type: $type\n        userId: $userId\n        childId: $childId\n        value: $value\n        currency: $currency\n        description: $description\n        status: $status\n        paymentRequests: $paymentRequests\n        sendInfoEmail: $sendInfoEmail\n      }\n    ) {\n      id\n    }\n  }\n": types.CreateOrUpdateUserTxDocument,
    "\n  mutation DeleteUserTransaction($id: String!) {\n    deleteTransaction(data: { id: $id }) {\n      success\n    }\n  }\n": types.DeleteUserTransactionDocument,
    "\n  query UserTransactions($filterByEmail: String) {\n    transactions(email: $filterByEmail) {\n      edges {\n        node {\n          id\n          timestamp\n          type\n          source\n          user {\n            id\n            username\n            email\n            mailingAddress {\n              name\n            }\n          }\n          child {\n            id\n            name\n            fullNumber\n          }\n          status\n          value\n          currency\n          description\n          bankReference\n          bankSourceAccountPrefix\n          bankSourceAccountNumber\n          bankSourceAccountCode\n          bankSourceAccountName\n          bankMessage\n          bankVariableSymbol\n          bankConstantSymbol\n          paymentRequests {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.UserTransactionsDocument,
    "\nquery BackofficeTxSummary($year: Int!) {\n  backofficeTxSummary(year: $year) {\n    email\n    value\n    child\n  }\n}": types.BackofficeTxSummaryDocument,
    "\n  query getUserDetail($email: String!) {\n    userDetail(email: $email) {\n      id\n      email\n      phone\n      role\n      legacyId\n      adoptions {\n        id\n        child {\n          name\n          fullNumber\n          mainPhoto\n        }\n        paymentInterval {\n          treaty\n        }\n        lastPayment\n        nextPayment\n      }\n      mailingAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n    backofficeGiftCertificates(email: $email) {\n      id\n      year\n      downloadAllowed\n      price\n    }\n  }\n": types.GetUserDetailDocument,
    "\n  mutation DeleteCertMutation($id: String!) {\n    backofficeRemoveGiftCertificate(id: $id) {\n      success\n    }\n  }\n": types.DeleteCertMutationDocument,
    "\n  query AdminSearchUserEmail($childNumbers: String, $state: String) {\n    adminSearchUserEmail(childNumbers: $childNumbers, state: $state)\n  }\n": types.AdminSearchUserEmailDocument,
    "\n  query GetUsersList($search: String) {\n    usersList(search: $search) {\n      edges {\n        node {\n          id\n          email\n          username\n          adoptions {\n            id\n            child {\n              name\n              fullNumber\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetUsersListDocument,
    "\n  mutation ChangeMyAddress(\n    $addressType: AddressType!\n    $name: String\n    $street: String\n    $city: String\n    $zip: String\n    $state: String\n    $phone: String\n    $isSameAsMailing: Boolean\n  ) {\n    changeMyAddress(\n      data: {\n        addressType: $addressType\n        name: $name\n        street: $street\n        city: $city\n        zip: $zip\n        state: $state\n        phone: $phone\n        isSameAsMailing: $isSameAsMailing\n      }\n    ) {\n      id\n      addressType\n      name\n      street\n      city\n      zip\n      state\n      phone\n      isSameAsMailing\n    }\n  }\n": types.ChangeMyAddressDocument,
    "\n  mutation ChangeAdoptionCertificateName($adoptionId: String!, $name: String!) {\n    changeAdoptionCertificateName(\n      data: { adoptionId: $adoptionId, name: $name }\n    ) {\n      success\n      message\n    }\n  }\n": types.ChangeAdoptionCertificateNameDocument,
    "\n  query MyAdoptions {\n    myAdoptions {\n      id\n      child {\n        name\n        fullNumber\n        mainPhoto\n      }\n      paymentInterval {\n        treaty\n      }\n      nextPayment\n      certificateName\n    }\n  }\n": types.MyAdoptionsDocument,
    "\n  mutation ChangePassword(\n    $newPassword: String!\n    $oldPassword: String\n    $token: String\n  ) {\n    changePassword(\n      data: {\n        newPassword: $newPassword\n        oldPassword: $oldPassword\n        token: $token\n      }\n    ) {\n      success\n      message\n    }\n  }\n": types.ChangePasswordDocument,
    "\n  mutation ResetPasswordRequest($email: String!, $captchaToken: String!) {\n    resetPasswordRequest(email: $email, captchaToken: $captchaToken) {\n      success\n      message\n    }\n  }\n": types.ResetPasswordRequestDocument,
    "\n  mutation VerifyEmailChange($token: String!) {\n    verifyEmailChange(token: $token) {\n      success\n      message\n    }\n  }\n": types.VerifyEmailChangeDocument,
    "\n  mutation CreateGiftCertificate(\n    $year: Int!\n    $name: String!\n    $company: String\n    $address: String!\n    $icRc: String\n    $dic: String\n  ) {\n    createGiftCertificate(\n      data: {\n        year: $year\n        name: $name\n        company: $company\n        address: $address\n        icRc: $icRc\n        dic: $dic\n      }\n    ) {\n      success\n      message\n    }\n  }\n": types.CreateGiftCertificateDocument,
    "\n  query MyPaymentRequests {\n    myPaymentRequests {\n      id\n      adoption {\n        child {\n          fullNumber\n        }\n        paymentInterval {\n          price\n        }\n      }\n      paid\n      paymentDate\n    }\n  }\n": types.MyPaymentRequestsDocument,
    "\n  query MyAddresses {\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n": types.MyAddressesDocument,
    "\n  mutation UserRegistration(\n    $email: String!\n    $password: String!\n    $phone: String\n    $captchaToken: String!\n  ) {\n    userRegistration(\n      data: { email: $email, password: $password, phone: $phone }\n      captchaToken: $captchaToken\n    ) {\n      success\n      message\n    }\n  }\n": types.UserRegistrationDocument,
    "\n  query MyTransactionsSummary {\n    myTransactionsSummary {\n      year\n      value\n      certificate {\n        id\n        downloadAllowed\n      }\n    }\n  }\n": types.MyTransactionsSummaryDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PaymentIntervals {\n    paymentIntervals {\n      id\n      treaty\n    }\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n"): (typeof documents)["\n  query PaymentIntervals {\n    paymentIntervals {\n      id\n      treaty\n    }\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ReserveChild($childNumber: Int!, $paymentIntervalId: String!) {\n    reserveChild(\n      childNumber: $childNumber\n      paymentIntervalId: $paymentIntervalId\n    ) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation ReserveChild($childNumber: Int!, $paymentIntervalId: String!) {\n    reserveChild(\n      childNumber: $childNumber\n      paymentIntervalId: $paymentIntervalId\n    ) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query child($fullNumber: String!) {\n    child(fullNumber: $fullNumber) {\n      id\n      name\n      number\n      fullNumber\n      mainPhoto\n      description\n      birthDate\n      sex\n      top\n      visible\n      state\n      preReserved\n      motherName\n      fatherName\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      school {\n        name\n      }\n      coordinator {\n        name\n      }\n      teaser\n      attachmentGroups {\n        id\n        title\n        attachments {\n          id\n          type\n          filename\n          rotationAngle\n          newPath\n        }\n      }\n      storage {\n        files {\n          name\n          path\n          link\n        }\n        directories {\n          name\n          files {\n            name\n            path\n            link\n          }\n        }\n      }\n    }\n    me {\n      role\n    }\n  }\n"): (typeof documents)["\n  query child($fullNumber: String!) {\n    child(fullNumber: $fullNumber) {\n      id\n      name\n      number\n      fullNumber\n      mainPhoto\n      description\n      birthDate\n      sex\n      top\n      visible\n      state\n      preReserved\n      motherName\n      fatherName\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      school {\n        name\n      }\n      coordinator {\n        name\n      }\n      teaser\n      attachmentGroups {\n        id\n        title\n        attachments {\n          id\n          type\n          filename\n          rotationAngle\n          newPath\n        }\n      }\n      storage {\n        files {\n          name\n          path\n          link\n        }\n        directories {\n          name\n          files {\n            name\n            path\n            link\n          }\n        }\n      }\n    }\n    me {\n      role\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeTopChild($childId: String!, $top: Boolean!) {\n    changeTopChild(childId: $childId, top: $top) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeTopChild($childId: String!, $top: Boolean!) {\n    changeTopChild(childId: $childId, top: $top) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeVisibleChild($childId: String!, $visible: Boolean!) {\n    changeVisibleChild(childId: $childId, visible: $visible) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeVisibleChild($childId: String!, $visible: Boolean!) {\n    changeVisibleChild(childId: $childId, visible: $visible) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query childList($state: String!) {\n    childList(state: $state) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          top\n          fullNumber\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query childList($state: String!) {\n    childList(state: $state) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          top\n          fullNumber\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query childSearch($text: String!) {\n    childSearch(text: $text) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          fullNumber\n          top\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query childSearch($text: String!) {\n    childSearch(text: $text) {\n      edges {\n        node {\n          name\n          number\n          teaser\n          fullNumber\n          top\n          description\n          mainPhoto\n          state\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MenuTreeBar {\n    menuTree(slug: \"hlavni-menu\") {\n      title\n      id\n      directUrl\n      page {\n        slug\n      }\n      children {\n        title\n        id\n        directUrl\n        page {\n          slug\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MenuTreeBar {\n    menuTree(slug: \"hlavni-menu\") {\n      title\n      id\n      directUrl\n      page {\n        slug\n      }\n      children {\n        title\n        id\n        directUrl\n        page {\n          slug\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query RandomAdoptionOffer($count: Int) {\n    randomAdoptionOffer(count: $count) {\n      name\n      fullNumber\n      mainPhoto\n    }\n  }\n"): (typeof documents)["\n  query RandomAdoptionOffer($count: Int) {\n    randomAdoptionOffer(count: $count) {\n      name\n      fullNumber\n      mainPhoto\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdoptionSummary {\n    adoptionSummary {\n      for_adoption\n      adopted\n      reserved\n    }\n  }\n"): (typeof documents)["\n  query AdoptionSummary {\n    adoptionSummary {\n      for_adoption\n      adopted\n      reserved\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MenuTreeNav {\n    menuTree(slug: \"hlavni-menu\") {\n      title\n      id\n      directUrl\n      page {\n        slug\n      }\n      children {\n        title\n        id\n        directUrl\n        page {\n          slug\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query MenuTreeNav {\n    menuTree(slug: \"hlavni-menu\") {\n      title\n      id\n      directUrl\n      page {\n        slug\n      }\n      children {\n        title\n        id\n        directUrl\n        page {\n          slug\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query QueryUserInfo {\n    me {\n      username\n      email\n      phone\n      role\n    }\n  }\n"): (typeof documents)["\n  query QueryUserInfo {\n    me {\n      username\n      email\n      phone\n      role\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($username: String!, $password: String!) {\n    login(data: { username: $username, password: $password }) {\n      user {\n        username\n        email\n        phone\n        role\n      }\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  mutation Login($username: String!, $password: String!) {\n    login(data: { username: $username, password: $password }) {\n      user {\n        username\n        email\n        phone\n        role\n      }\n      accessToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Impersonation($userId: String!) {\n    getTokenForImpersonation(userId: $userId) {\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  mutation Impersonation($userId: String!) {\n    getTokenForImpersonation(userId: $userId) {\n      accessToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsSlug($slug: String!) {\n    news(slug: $slug) {\n      id\n      title\n      createdAt\n      slug\n      teaser\n      content\n      mainPhoto\n    }\n  }\n"): (typeof documents)["\n  query NewsSlug($slug: String!) {\n    news(slug: $slug) {\n      id\n      title\n      createdAt\n      slug\n      teaser\n      content\n      mainPhoto\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsList {\n    newsList {\n      edges {\n        node {\n          id\n          title\n          slug\n          content\n          createdAt\n          teaser\n          mainPhoto\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsList {\n    newsList {\n      edges {\n        node {\n          id\n          title\n          slug\n          content\n          createdAt\n          teaser\n          mainPhoto\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query page($slug: String!) {\n    page(slug: $slug) {\n      title\n      content\n    }\n  }\n"): (typeof documents)["\n  query page($slug: String!) {\n    page(slug: $slug) {\n      title\n      content\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query photoGallery($slug: String!) {\n    photoGallery(slug: $slug) {\n      id\n      slug\n      name\n      description\n      createdAt\n      photos {\n        id\n        description\n        filename\n        rotationAngle\n      }\n    }\n  }\n"): (typeof documents)["\n  query photoGallery($slug: String!) {\n    photoGallery(slug: $slug) {\n      id\n      slug\n      name\n      description\n      createdAt\n      photos {\n        id\n        description\n        filename\n        rotationAngle\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GalleriesList {\n    galleriesList {\n      edges {\n        node {\n          id\n          name\n          slug\n          description\n          createdAt\n          photos {\n            id\n            description\n            filename\n            rotationAngle\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GalleriesList {\n    galleriesList {\n      edges {\n        node {\n          id\n          name\n          slug\n          description\n          createdAt\n          photos {\n            id\n            description\n            filename\n            rotationAngle\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Supporters {\n    supporters {\n      id\n      name\n      description\n      logo\n      link\n    }\n  }\n"): (typeof documents)["\n  query Supporters {\n    supporters {\n      id\n      name\n      description\n      logo\n      link\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query QueuedEmailList($status: String, $email: String) {\n    queuedEmailList(status: $status, email: $email) {\n      edges {\n        node {\n          id\n          createdAt\n          sentAt\n          type\n          subject\n          htmlBody\n          email\n          status\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query QueuedEmailList($status: String, $email: String) {\n    queuedEmailList(status: $status, email: $email) {\n      edges {\n        node {\n          id\n          createdAt\n          sentAt\n          type\n          subject\n          htmlBody\n          email\n          status\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeEmailTemplate(\n    $id: String!\n    $subject: String!\n    $body: String!\n  ) {\n    changeEmailTemplate(data: { id: $id, subject: $subject, body: $body }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeEmailTemplate(\n    $id: String!\n    $subject: String!\n    $body: String!\n  ) {\n    changeEmailTemplate(data: { id: $id, subject: $subject, body: $body }) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query EmailTemplates {\n    emailTemplates {\n      id\n      type\n      subject\n      body\n    }\n  }\n"): (typeof documents)["\n  query EmailTemplates {\n    emailTemplates {\n      id\n      type\n      subject\n      body\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AttachVideoLink(\n    $childNumber: Int!\n    $groupName: String!\n    $link: String!\n  ) {\n    attachVideoLink(\n      input: {\n        childNumber: $childNumber\n        attachmentGroup: $groupName\n        link: $link\n      }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation AttachVideoLink(\n    $childNumber: Int!\n    $groupName: String!\n    $link: String!\n  ) {\n    attachVideoLink(\n      input: {\n        childNumber: $childNumber\n        attachmentGroup: $groupName\n        link: $link\n      }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CancelAdoption($adoptionId: String!, $keep: Boolean!) {\n    backofficeCancelAdoption(adoptionId: $adoptionId, keep: $keep) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation CancelAdoption($adoptionId: String!, $keep: Boolean!) {\n    backofficeCancelAdoption(adoptionId: $adoptionId, keep: $keep) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminAdoptionPaymentIntervalChange($adoptionId: String!, $paymentIntervalId: String!) {\n    adminAdoptionPaymentIntervalChange(adoptionId: $adoptionId, paymentIntervalId: $paymentIntervalId) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation AdminAdoptionPaymentIntervalChange($adoptionId: String!, $paymentIntervalId: String!) {\n    adminAdoptionPaymentIntervalChange(adoptionId: $adoptionId, paymentIntervalId: $paymentIntervalId) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAdoptionDetails($adoptionId: String!) {\n    adminAdoptionDetail(adoptionId: $adoptionId) {\n      id\n      user {\n        email\n      }\n      child {\n        name\n        fullNumber\n      }\n      paymentRequests {\n        id\n        paymentDate\n        paid\n        skip\n        intervalDate {\n          paymentInterval {\n            id\n            treaty\n            price\n            intervalReminders {\n              id\n              daysBefore\n            }\n          }\n        }\n        reminders {\n          remindedAt\n          intervalReminder {\n            id\n            daysBefore\n          }\n        }\n      }\n      paymentInterval {\n        id\n        treaty\n      }\n      lastPayment\n      lastPaymentTx\n      nextPayment\n    }\n    \n    paymentIntervals {\n      id\n      treaty\n    }\n  }\n"): (typeof documents)["\n  query getAdoptionDetails($adoptionId: String!) {\n    adminAdoptionDetail(adoptionId: $adoptionId) {\n      id\n      user {\n        email\n      }\n      child {\n        name\n        fullNumber\n      }\n      paymentRequests {\n        id\n        paymentDate\n        paid\n        skip\n        intervalDate {\n          paymentInterval {\n            id\n            treaty\n            price\n            intervalReminders {\n              id\n              daysBefore\n            }\n          }\n        }\n        reminders {\n          remindedAt\n          intervalReminder {\n            id\n            daysBefore\n          }\n        }\n      }\n      paymentInterval {\n        id\n        treaty\n      }\n      lastPayment\n      lastPaymentTx\n      nextPayment\n    }\n    \n    paymentIntervals {\n      id\n      treaty\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminSkipPaymentRequest($paymentRequestId: String! $skip: Boolean!) {\n    adminSkipPaymentRequest(paymentRequestId: $paymentRequestId, skip: $skip) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation AdminSkipPaymentRequest($paymentRequestId: String! $skip: Boolean!) {\n    adminSkipPaymentRequest(paymentRequestId: $paymentRequestId, skip: $skip) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPostList {\n    postList {\n      userId\n      childNumbers\n      address {\n        id\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPostList {\n    postList {\n      userId\n      childNumbers\n      address {\n        id\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetBankCertificates {\n    bankCertificates {\n      id\n      filename\n      active\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query GetBankCertificates {\n    bankCertificates {\n      id\n      filename\n      active\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query BackofficeChildList(\n    $text: String\n    $state: String\n    $visible: Boolean\n    $coordinatorId: String\n  ) {\n    backofficeChildList(\n      input: { text: $text, visible: $visible, coordinatorId: $coordinatorId, state: $state }\n    ) {\n      id\n      name\n      number\n      fullNumber\n      state\n      visible\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      coordinator {\n        id\n        name\n        sign\n      }\n    }\n    listCoordinators {\n      id\n      name\n      sign\n    }\n  }\n"): (typeof documents)["\n  query BackofficeChildList(\n    $text: String\n    $state: String\n    $visible: Boolean\n    $coordinatorId: String\n  ) {\n    backofficeChildList(\n      input: { text: $text, visible: $visible, coordinatorId: $coordinatorId, state: $state }\n    ) {\n      id\n      name\n      number\n      fullNumber\n      state\n      visible\n      adoption {\n        id\n        user {\n          email\n        }\n      }\n      coordinator {\n        id\n        name\n        sign\n      }\n    }\n    listCoordinators {\n      id\n      name\n      sign\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateOrUpdateNews(\n    $id: String\n    $title: String!\n    $teaser: String!\n    $mainPhoto: String\n  ) {\n    createOrUpdateNews(\n      input: { id: $id, title: $title, teaser: $teaser, mainPhoto: $mainPhoto }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrUpdateNews(\n    $id: String\n    $title: String!\n    $teaser: String!\n    $mainPhoto: String\n  ) {\n    createOrUpdateNews(\n      input: { id: $id, title: $title, teaser: $teaser, mainPhoto: $mainPhoto }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query BackofficeNewsList {\n    newsList {\n      edges {\n        node {      \n          id\n          title\n          slug\n          teaser\n          mainPhoto\n          content\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BackofficeNewsList {\n    newsList {\n      edges {\n        node {      \n          id\n          title\n          slug\n          teaser\n          mainPhoto\n          content\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query BackofficeExpiredPaymentRequests {\n    backofficeExpiredPaymentRequests {\n      id\n      childNumber\n      childName\n      reserved\n      adoptionId\n      email\n      interval\n      paymentDate\n      daysLate\n    }\n  }\n"): (typeof documents)["\n  query BackofficeExpiredPaymentRequests {\n    backofficeExpiredPaymentRequests {\n      id\n      childNumber\n      childName\n      reserved\n      adoptionId\n      email\n      interval\n      paymentDate\n      daysLate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUpcomingReminders {\n    adminUpcomingReminders {\n      id\n      remindedAt\n      reminderDate\n      email {\n        id\n        createdAt\n        email\n        type\n        subject\n        status\n        sentAt\n        htmlBody\n      }\n      intervalReminder {\n        daysBefore\n        paymentInterval {\n          treaty\n        }\n      }\n      paymentRequest {\n        id\n        paymentDate\n        paid\n        adoption {\n          id\n          child {\n            fullNumber\n          }\n          user {\n            email\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUpcomingReminders {\n    adminUpcomingReminders {\n      id\n      remindedAt\n      reminderDate\n      email {\n        id\n        createdAt\n        email\n        type\n        subject\n        status\n        sentAt\n        htmlBody\n      }\n      intervalReminder {\n        daysBefore\n        paymentInterval {\n          treaty\n        }\n      }\n      paymentRequest {\n        id\n        paymentDate\n        paid\n        adoption {\n          id\n          child {\n            fullNumber\n          }\n          user {\n            email\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTxDetail($id: String) {\n    adminTransactionDetail(id: $id) {\n      id\n      timestamp\n      type\n      source\n      user {\n        id\n      }\n      child {\n        id\n      }\n      status\n      value\n      currency\n      description\n      bankReference\n      bankSourceAccountPrefix\n      bankSourceAccountNumber\n      bankSourceAccountCode\n      bankSourceAccountName\n      bankMessage\n      bankVariableSymbol\n      bankConstantSymbol\n      paymentRequests {\n        id\n      }\n    }\n    usersList {\n      edges {\n        node {\n          id\n          mailingAddress {\n            name\n          }\n          email\n        }\n      }\n    }\n    adminChildList {\n      id\n      fullNumber\n      number\n      name\n      adoption {\n        user {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTxDetail($id: String) {\n    adminTransactionDetail(id: $id) {\n      id\n      timestamp\n      type\n      source\n      user {\n        id\n      }\n      child {\n        id\n      }\n      status\n      value\n      currency\n      description\n      bankReference\n      bankSourceAccountPrefix\n      bankSourceAccountNumber\n      bankSourceAccountCode\n      bankSourceAccountName\n      bankMessage\n      bankVariableSymbol\n      bankConstantSymbol\n      paymentRequests {\n        id\n      }\n    }\n    usersList {\n      edges {\n        node {\n          id\n          mailingAddress {\n            name\n          }\n          email\n        }\n      }\n    }\n    adminChildList {\n      id\n      fullNumber\n      number\n      name\n      adoption {\n        user {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminPaymentRequest($userId: String! $childId: String!) {\n    adminPaymentRequest(userId: $userId, childId: $childId) {\n      id\n      paymentDate\n      paid\n      intervalDate {\n        paymentInterval {\n          price\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminPaymentRequest($userId: String! $childId: String!) {\n    adminPaymentRequest(userId: $userId, childId: $childId) {\n      id\n      paymentDate\n      paid\n      intervalDate {\n        paymentInterval {\n          price\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateOrUpdateUserTx(\n    $id: String\n    $timestamp: DateTime!\n    $type: String!\n    $userId: UserId\n    $childId: ChildId\n    $value: Int!\n    $currency: String!\n    $description: String\n    $status: String!\n    $paymentRequests: [PaymentRequestId!]!\n    $sendInfoEmail: Boolean\n  ) {\n    createOrUpdateTransaction(\n      data: {\n        id: $id\n        timestamp: $timestamp\n        type: $type\n        userId: $userId\n        childId: $childId\n        value: $value\n        currency: $currency\n        description: $description\n        status: $status\n        paymentRequests: $paymentRequests\n        sendInfoEmail: $sendInfoEmail\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrUpdateUserTx(\n    $id: String\n    $timestamp: DateTime!\n    $type: String!\n    $userId: UserId\n    $childId: ChildId\n    $value: Int!\n    $currency: String!\n    $description: String\n    $status: String!\n    $paymentRequests: [PaymentRequestId!]!\n    $sendInfoEmail: Boolean\n  ) {\n    createOrUpdateTransaction(\n      data: {\n        id: $id\n        timestamp: $timestamp\n        type: $type\n        userId: $userId\n        childId: $childId\n        value: $value\n        currency: $currency\n        description: $description\n        status: $status\n        paymentRequests: $paymentRequests\n        sendInfoEmail: $sendInfoEmail\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUserTransaction($id: String!) {\n    deleteTransaction(data: { id: $id }) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUserTransaction($id: String!) {\n    deleteTransaction(data: { id: $id }) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserTransactions($filterByEmail: String) {\n    transactions(email: $filterByEmail) {\n      edges {\n        node {\n          id\n          timestamp\n          type\n          source\n          user {\n            id\n            username\n            email\n            mailingAddress {\n              name\n            }\n          }\n          child {\n            id\n            name\n            fullNumber\n          }\n          status\n          value\n          currency\n          description\n          bankReference\n          bankSourceAccountPrefix\n          bankSourceAccountNumber\n          bankSourceAccountCode\n          bankSourceAccountName\n          bankMessage\n          bankVariableSymbol\n          bankConstantSymbol\n          paymentRequests {\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserTransactions($filterByEmail: String) {\n    transactions(email: $filterByEmail) {\n      edges {\n        node {\n          id\n          timestamp\n          type\n          source\n          user {\n            id\n            username\n            email\n            mailingAddress {\n              name\n            }\n          }\n          child {\n            id\n            name\n            fullNumber\n          }\n          status\n          value\n          currency\n          description\n          bankReference\n          bankSourceAccountPrefix\n          bankSourceAccountNumber\n          bankSourceAccountCode\n          bankSourceAccountName\n          bankMessage\n          bankVariableSymbol\n          bankConstantSymbol\n          paymentRequests {\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery BackofficeTxSummary($year: Int!) {\n  backofficeTxSummary(year: $year) {\n    email\n    value\n    child\n  }\n}"): (typeof documents)["\nquery BackofficeTxSummary($year: Int!) {\n  backofficeTxSummary(year: $year) {\n    email\n    value\n    child\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getUserDetail($email: String!) {\n    userDetail(email: $email) {\n      id\n      email\n      phone\n      role\n      legacyId\n      adoptions {\n        id\n        child {\n          name\n          fullNumber\n          mainPhoto\n        }\n        paymentInterval {\n          treaty\n        }\n        lastPayment\n        nextPayment\n      }\n      mailingAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n    backofficeGiftCertificates(email: $email) {\n      id\n      year\n      downloadAllowed\n      price\n    }\n  }\n"): (typeof documents)["\n  query getUserDetail($email: String!) {\n    userDetail(email: $email) {\n      id\n      email\n      phone\n      role\n      legacyId\n      adoptions {\n        id\n        child {\n          name\n          fullNumber\n          mainPhoto\n        }\n        paymentInterval {\n          treaty\n        }\n        lastPayment\n        nextPayment\n      }\n      mailingAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n    }\n    backofficeGiftCertificates(email: $email) {\n      id\n      year\n      downloadAllowed\n      price\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCertMutation($id: String!) {\n    backofficeRemoveGiftCertificate(id: $id) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCertMutation($id: String!) {\n    backofficeRemoveGiftCertificate(id: $id) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSearchUserEmail($childNumbers: String, $state: String) {\n    adminSearchUserEmail(childNumbers: $childNumbers, state: $state)\n  }\n"): (typeof documents)["\n  query AdminSearchUserEmail($childNumbers: String, $state: String) {\n    adminSearchUserEmail(childNumbers: $childNumbers, state: $state)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUsersList($search: String) {\n    usersList(search: $search) {\n      edges {\n        node {\n          id\n          email\n          username\n          adoptions {\n            id\n            child {\n              name\n              fullNumber\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUsersList($search: String) {\n    usersList(search: $search) {\n      edges {\n        node {\n          id\n          email\n          username\n          adoptions {\n            id\n            child {\n              name\n              fullNumber\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeMyAddress(\n    $addressType: AddressType!\n    $name: String\n    $street: String\n    $city: String\n    $zip: String\n    $state: String\n    $phone: String\n    $isSameAsMailing: Boolean\n  ) {\n    changeMyAddress(\n      data: {\n        addressType: $addressType\n        name: $name\n        street: $street\n        city: $city\n        zip: $zip\n        state: $state\n        phone: $phone\n        isSameAsMailing: $isSameAsMailing\n      }\n    ) {\n      id\n      addressType\n      name\n      street\n      city\n      zip\n      state\n      phone\n      isSameAsMailing\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeMyAddress(\n    $addressType: AddressType!\n    $name: String\n    $street: String\n    $city: String\n    $zip: String\n    $state: String\n    $phone: String\n    $isSameAsMailing: Boolean\n  ) {\n    changeMyAddress(\n      data: {\n        addressType: $addressType\n        name: $name\n        street: $street\n        city: $city\n        zip: $zip\n        state: $state\n        phone: $phone\n        isSameAsMailing: $isSameAsMailing\n      }\n    ) {\n      id\n      addressType\n      name\n      street\n      city\n      zip\n      state\n      phone\n      isSameAsMailing\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangeAdoptionCertificateName($adoptionId: String!, $name: String!) {\n    changeAdoptionCertificateName(\n      data: { adoptionId: $adoptionId, name: $name }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeAdoptionCertificateName($adoptionId: String!, $name: String!) {\n    changeAdoptionCertificateName(\n      data: { adoptionId: $adoptionId, name: $name }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyAdoptions {\n    myAdoptions {\n      id\n      child {\n        name\n        fullNumber\n        mainPhoto\n      }\n      paymentInterval {\n        treaty\n      }\n      nextPayment\n      certificateName\n    }\n  }\n"): (typeof documents)["\n  query MyAdoptions {\n    myAdoptions {\n      id\n      child {\n        name\n        fullNumber\n        mainPhoto\n      }\n      paymentInterval {\n        treaty\n      }\n      nextPayment\n      certificateName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ChangePassword(\n    $newPassword: String!\n    $oldPassword: String\n    $token: String\n  ) {\n    changePassword(\n      data: {\n        newPassword: $newPassword\n        oldPassword: $oldPassword\n        token: $token\n      }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ChangePassword(\n    $newPassword: String!\n    $oldPassword: String\n    $token: String\n  ) {\n    changePassword(\n      data: {\n        newPassword: $newPassword\n        oldPassword: $oldPassword\n        token: $token\n      }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResetPasswordRequest($email: String!, $captchaToken: String!) {\n    resetPasswordRequest(email: $email, captchaToken: $captchaToken) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPasswordRequest($email: String!, $captchaToken: String!) {\n    resetPasswordRequest(email: $email, captchaToken: $captchaToken) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VerifyEmailChange($token: String!) {\n    verifyEmailChange(token: $token) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyEmailChange($token: String!) {\n    verifyEmailChange(token: $token) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateGiftCertificate(\n    $year: Int!\n    $name: String!\n    $company: String\n    $address: String!\n    $icRc: String\n    $dic: String\n  ) {\n    createGiftCertificate(\n      data: {\n        year: $year\n        name: $name\n        company: $company\n        address: $address\n        icRc: $icRc\n        dic: $dic\n      }\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGiftCertificate(\n    $year: Int!\n    $name: String!\n    $company: String\n    $address: String!\n    $icRc: String\n    $dic: String\n  ) {\n    createGiftCertificate(\n      data: {\n        year: $year\n        name: $name\n        company: $company\n        address: $address\n        icRc: $icRc\n        dic: $dic\n      }\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyPaymentRequests {\n    myPaymentRequests {\n      id\n      adoption {\n        child {\n          fullNumber\n        }\n        paymentInterval {\n          price\n        }\n      }\n      paid\n      paymentDate\n    }\n  }\n"): (typeof documents)["\n  query MyPaymentRequests {\n    myPaymentRequests {\n      id\n      adoption {\n        child {\n          fullNumber\n        }\n        paymentInterval {\n          price\n        }\n      }\n      paid\n      paymentDate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyAddresses {\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyAddresses {\n    myAddresses {\n      mailingAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n      }\n      contractAddress {\n        addressType\n        name\n        street\n        city\n        zip\n        state\n        phone\n        isSameAsMailing\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserRegistration(\n    $email: String!\n    $password: String!\n    $phone: String\n    $captchaToken: String!\n  ) {\n    userRegistration(\n      data: { email: $email, password: $password, phone: $phone }\n      captchaToken: $captchaToken\n    ) {\n      success\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation UserRegistration(\n    $email: String!\n    $password: String!\n    $phone: String\n    $captchaToken: String!\n  ) {\n    userRegistration(\n      data: { email: $email, password: $password, phone: $phone }\n      captchaToken: $captchaToken\n    ) {\n      success\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyTransactionsSummary {\n    myTransactionsSummary {\n      year\n      value\n      certificate {\n        id\n        downloadAllowed\n      }\n    }\n  }\n"): (typeof documents)["\n  query MyTransactionsSummary {\n    myTransactionsSummary {\n      year\n      value\n      certificate {\n        id\n        downloadAllowed\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;